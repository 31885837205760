<template>
  <v-footer
    transition="scroll-y-reverse-transition"
    app
    color="primary"
    padless
    fixed
  >
    <v-col class="text-center white--text" cols="12">
      From your DevOps Team with <v-icon color="red" v-text="'$love'" />
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'appFooter',
};
</script>

<style scoped>
</style>
